import React, { Suspense } from "react";
import { Helmet } from "react-helmet";
// Screens

import { HashRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./components/ScrolltoTop";
import ClipLoader from "react-spinners/ClipLoader";
// import Spinner from "react-text-spinners";

const loading = (
  <div className="row" style={{ marginTop: "45vh", position: "relative" }}>
    <center>
      <div className="col-12">
        <ClipLoader
          color={"#3b5998"}
          loading={true}
          size={110}
          aria-label="Loading Spinner"
          data-testid="loader"
        ></ClipLoader>
        <div style={{ position: "relative", top: "-80px" }}>
          <div className="row d-flex justify-content-center">
            <div>
              {" "}
              <p
                style={{
                  color: "#3b5998",
                  fontSize: "10px",
                  fontWeight: "900",
                  fontFamily: "mulish",
                }}
              >
                {" "}
                NO POVERTY <br />
                <br />
              </p>
              <p
                style={{
                  color: "red",
                  fontSize: "10px",
                  fontWeight: "900",
                  fontFamily: "mulish",
                }}
              >
                ONLY DIGNITY
              </p>
            </div>
          </div>
        </div>
      </div>
    </center>

    {/* <Spinner theme="dots2" /> : <p>{"message"}</p> */}
  </div>
);
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
export default function App() {
  return (
    <>
      <HashRouter>
        <Suspense fallback={loading}>
          <ScrollToTop />
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </>
  );
}
